<template>
    <div>

        <v-navigation-drawer app v-model="drawer" temporary right :style="{ zIndex: -1  }">
    <v-list>
      <v-list-item-group v-model="selectedItem" color="primary" rounded>
        <v-avatar v-if="$store.state.ProfilePic != ''" class="d-flex justify-center mt-2" size="150"
          style="cursor: pointer;margin-left: 50px;">
          <v-img :src="$store.state.ProfilePic"></v-img>
        </v-avatar>
        <v-avatar v-else class="d-flex justify-center" color="blue lighten-4" size="150"
          style="cursor: pointer;margin-left: 50px;">
        </v-avatar>
        <v-list-item v-for="(item, i) in items" :key="i" dense class="mt-2">
          <v-list-item-avatar>
            <v-icon class="pink--text">{{ item.icon }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content @click="navigate(item.router)">
            <v-list-item-title class="font-weigth-bold">{{ item.text }}
              <v-badge v-if="item.text == 'Messages' && $globalData.MsgCount > 0" color="primary"
                :content="$globalData.MsgCount">
              </v-badge>
            </v-list-item-title>
            <v-list-item-subtitle>{{ item.subtittle }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
         <v-bottom-navigation
      :value="selectedItem"
      color="pink"
      grow
    >
      <v-btn @click="navigate('/profileMatcher')">
        <span>Your Matches</span>
        <v-icon>mdi-account-multiple</v-icon>
      </v-btn>
      <v-btn  @click="navigate('/userProfile')">
        <span>Profile</span>
        <v-icon>mdi-account</v-icon>
      </v-btn>
      <v-btn  @click="navigate('/Inbox')">
        <span>Messages</span>
        <v-icon>mdi-message-text-outline</v-icon>
      </v-btn>
      <v-btn   @click.stop="drawer = !drawer">
        <span v-if="!drawer">Menu</span>
        <span v-else>close</span>
        <v-icon v-if="!drawer">mdi-menu</v-icon>
        <v-icon v-else>mdi-close</v-icon>

      </v-btn>
    </v-bottom-navigation>
   
</div>

  </template>
  <script>
  import EventServices from '@/services/EventServices';
  export default {
   data(){
    return{
        selectedItem: -1,
      drawer: false,
      items: [
        // { text: 'Matches', router: '/profileMatcher', icon: 'mdi-account-multiple', subtittle: 'Find your soul' },
        // { text: 'Profile', router: '/userProfile', icon: 'mdi-account', subtittle: 'Edit your profile' },
        // { text: 'Messages', router: '/Inbox', icon: 'mdi-message-text-outline', subtittle: 'Check your messages' },
        { text: 'Refer & Earn', router: '/Referral', icon: 'mdi-currency-inr', subtittle: 'Invite friends and earn rewards' },
        { text: 'Logout', router: '/', icon: 'mdi-logout', subtittle: '' }
      ],
    }
   },
   methods: {
    ClearLocalStorage() {
      localStorage.setItem("clientId", ""),
        localStorage.setItem("ProfilePic", "")
        // localStorage.setItem("MesssageList","")
        localStorage.setItem("offlineMsg","N")
    },
    navigate(item) {
      console.log("item",item);
      
      if (item == "/") {
        this.Logout()
      }else{

        this.curRouter = this.$route.path
        if (item != this.curRouter) {
          this.curRouter = item
          this.$router.push(item);
        }
      }
    },
    Logout() {
      let logReq = { clientId: localStorage.getItem("clientId") };
      EventServices.Logout(logReq)
        .then((response) => {
          if (response.data.status == "S") {
            this.ClearLocalStorage()
            this.$router.push("/")
          } else {
            this.$globalData.overlay = false
            this.MessageBar("E", response.data.errMsg);
          }
        })


    }
  },
  }
</script>