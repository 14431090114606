<template>
    <v-container class="d-flex justify-center mt-5">
      <v-card max-width="400" elevation="3" class="rounded-2xl">
        <v-card-text class="text-center">
          <v-avatar size="120" class="mx-auto mb-4">
            <v-img src="https://via.placeholder.com/120" alt="Client Image" />
          </v-avatar>
          <h2 class="font-weight-bold mb-2">John Doe</h2>
          <p class="text-gray-600">Height: 6 ft 1 in</p>
        </v-card-text>
  
        <v-divider></v-divider>
  
        <v-card-actions class="d-flex justify-center py-4">
          <v-btn color="primary" outlined class="mx-2 rounded-xl">
            View Profile
          </v-btn>
          <v-btn color="success" class="mx-2 rounded-xl">
            Chat
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'ClientCardView',
  };
  </script>
  
  <style scoped>
  .text-gray-600 {
    color: #757575;
  }
  </style>
  