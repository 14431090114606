<template>
    <div>
<profileMatches></profileMatches>
    </div>
</template>
<script>
import profileMatches from './HomeComponents/profileMatches.vue';
export default{
    data(){
        return{

        }
    },
    components:{
        profileMatches
    }
}
</script>